export default {
  data() {
    return {
      isVisible: false
    };
  },

  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
  }

};