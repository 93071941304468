export default {
  data() {
    return {
      dayOne: [{
        title: 'Opening Remarks',
        place: 'Virtual',
        time: '12pm CET',
        bottomImage: 'border-2.svg',
        topImage: 'border-3.svg',
        subtitle: 'Klasse Klima from Berlin and Zurich'
      }, {
        title: 'The European Green Deal and Climate Justice',
        type: 'Panel',
        place: 'Virtual',
        time: '12.15pm–2pm CET',
        moderation: 'Tobias Gralke',
        topImage: 'border-1.svg',
        bottomImage: 'border-12.svg',
        description: 'The talks in this panel will provide introductions to the state of international and European climate policy from a global justice perspective. Speakers will also present just alternatives and strategies for their realization. The presentations will be followed by an open discussion.',
        sections: [{
          speakers: ['Katja Voigt', 'Nessim Achouche'],
          title: 'Climate Justice at the Core of International Climate Politics: The Counterexample of the European Green Deal'
        }, {
          speakers: ['Tobias Bachmann'],
          title: 'A Good Life for All? Global Climate Justice! Why Climate Protection in Europe won’t Solve the Crisis'
        }, {
          speakers: ['Dušan Pajović'],
          title: 'Beyond a Green New Deal: DiEM25’s Plan to Democratize Europe'
        }]
      }, {
        title: 'Art, Architecture, Design, and the Climate Crisis: Old Bauhaus Politics',
        type: 'Panel',
        place: 'Virtual',
        time: '3pm-5pm CET',
        moderation: 'Kaja Ninnis',
        leftImage: 'border-4.svg',
        rightImage: 'border-14.svg',
        description: 'The talks in this panel will explore the entangled history of modern creative practice, globalized industrial capitalism, and systems of power. Speakers will revisit the Bauhaus reference by presenting the school’ s and movement’s influence from a decolonial-ecological perspective, followed by an open discussion.',
        sections: [{
          speakers: ['Isabell Schnalle', 'Lisa Hoffmann'],
          title: 'Decomposing Narratives of the Many Bauhauses'
        }, {
          speakers: ['Hicham Khalidi', 'Prof. Rolando Vázquez'],
          title: 'A New Bauhaus? The Debate for a More Inclusive Europe'
        }, {
          speakers: ['Prof. Claudia Mareis'],
          title: 'Un-learning Histories of Resilient Futures'
        }]
      }, {
        title: 'Public Screening at ZHdK, Zurich',
        type: 'Satellite Event',
        place: 'Physical',
        time: '11am CET',
        topImage: 'border-11.svg',
        bottomImage: 'border-7.svg',
        description: 'March 5 & 6, <a target="_blank" href="https://www.zhdk.ch/veranstaltung/46959" class="underline">public screening at ZHdK</a>, hosted by <a target="_blank" href="https://www.zhdk.ch/veranstaltung/46959" class="underline">School of Commons</a>  in collaboration with  <a target="_blank" href="https://www.zhdk.ch/nachhaltigkeit" class="underline">re-source | Sustainability in the Arts</a><br>Address: ZHdK Toni-Areal, Pfingstweidstrasse 96, 8005 Zurich, room 4.T48.<br><br>At ZHdK Zurich, School of Commons will host a public screening of the full symposium, starting on Saturday at 11am CET and on Sunday at 12pm CET. With the aim of gathering likeminded artists and designers who want to reflect on their own response-ability as activists, the event will provide a space to connect and exchange. Although Zurich is not part of the EU, its design professionals and students are involved in transnational projects, some of which are connected to the NEB. On Sunday evening, an on-site get-together at ZHdK will underline this local link from NEB and the symposium to Zurich. For more information, see column right (Sunday).'
      }],
      dayTwo: [{
        title: 'Claiming Creative Response-Ability',
        type: 'Panel',
        place: 'Virtual',
        time: '12pm-2pm CET',
        moderation: 'Lena Schubert',
        rightImage: 'border-6.svg',
        leftImage: 'border-13.svg',
        description: 'The talks in this panel will present a variety of constructive, artistic and designerly strategies for politicized engagement in institutional transformation agendas coming from the fields of activism and public sector work. The presentations will be followed by an open discussion.',
        sections: [{
          speakers: ['Marie Rosenkranz'],
          title: 'The Politics of Autonomy: Artistic Agency in Climate Activism'
        }, {
          speakers: ['Bobby Jewell', 'Hwei Fan Liang'],
          title: 'Architects Climate Action Network: Noise Makers and Policy Makers'
        }, {
          speakers: ['Caroline Paulick-Thiel', 'Dr. Christoph Gran'],
          title: 'Democratising Transformation: Co-Designing Policies for 1.5° Futures'
        }]
      }, {
        title: 'Entangling for a Just Design of Climate Politics',
        moderation: 'Bea Mensing, Julia Rosenstock',
        type: 'Workshop',
        place: 'Virtual',
        time: '3pm-5pm CET',
        topImage: 'border-8.svg',
        bottomImage: 'border-5.svg',
        description: 'This concluding workshop will offer to participants a chance to connect and to explore affinities, commonalities, and shared potentials beyond disciplinary and personal niches. Split into small groups, participants will be invited to position themselves on a map where they can uncover interrelations with others. They will discuss their personal linkages with the preceding panels, and learn from each other’s experiences. A closing plenary will provide a space to negotiate open questions, share resources, and consider potential future interplays.'
      }, {
        title: 'Apèro: How to design just in the crisis?, ZHdK Zurich',
        type: 'Satellite Event',
        place: 'Physical',
        time: '5:30pm CET',
        topImage: 'border-11.svg',
        bottomImage: 'border-7.svg',
        description: 'Address: ZHdK Toni-Areal, Pfingstweidstrasse 96, 8005 Zurich, room 4.T48.<br><br>At ZHdK Zurich, with the end of the Symposium, there will be a get-together titled <a target="_blank" href="https://www.zhdk.ch/veranstaltung/46959" class="underline">How to Design Just in the Crisis?</a> with Marea Hildebrand from School of Commons, <a target="_blank" href="https://www.zhdk.ch/en/person/flurina-gradin-9958" class="underline">Flurina Gradin</a> of the ZHdK Department of Design, and Lili Hillerich and Johann Otten from Klasse Klima. We will seek exchange about artistic practices of responsibility, ask about the politicizability of aesthetic processes and how climate justice can be a natural part of creative work at the intersection of material and ideal transformation. Drinks will be provided. Besides, at ZHdK Zurich, there will be a public screening of the full symposium. For more information, see column left (Saturday).'
      }, {
        title: 'Collective Walking Tour <span class="font-editorial-new-italic">From Bauhaus to Bauhaus</span>, Berlin',
        type: 'Satellite Event',
        place: 'Physical',
        time: '18:45pm CET',
        bottomImage: 'border-2.svg',
        topImage: 'border-3.svg',
        description: 'Hosted by Members of <a target="_blank" href="https://www.klasseklima.org" class="underline">Klasse Klima</a>.<br>From the BAUHAUS building market to the Bauhaus building site, we invite you to a strollological end to the symposium. In addition to passing various Bauhauses, we will be accompanied musically by <span class="font-editorial-new-italic">Bauhaus</span> and toast with products from the <span class="font-editorial-new-italic">Brauhaus</span>. Organized by Antonia Grohmann, Lisa Hoffmann, and Isabell Schnalle. <br><br>Schedule:<br><div class="pl-0 flex flex-wrap"><div class="w-1/7">18.45</div><div class="w-6/7">Meeting at <a target="_blank" href="https://wego.here.com/deutschland/berlin/hardware-house-garden-shop/bauhaus--276u33d9-f9c695ec570f42e988272acfbae9638e?map=52.48661,13.42191,15,normal" class="underline">BAUHAUS Baumarkt (Hasenheide 109, 10967 Berlin)</a></div><div class="w-1/7">19.00</div><div class="w-6/7">Start walking     &nbsp;</div><div class="w-1/7">20.00</div><div class="w-6/7">Interim meeting at <a target="_blank" href="https://share.here.com/p/s-Yz1tdXNldW07aWQ9Mjc2dTMzZDgtMTUxZTY2NGQwZWFhNGUyMTlkZjY0Zjk1Zjc3MmZjZDI7bGF0PTUyLjUwNjcxO2xvbj0xMy4zODE5MjtuPU1hcnRpbi1Hcm9waXVzLUJhdTtubGF0PTUyLjUwNzE5O25sb249MTMuMzgxODY7cGg9JTJCNDk0OTMwMjU0ODYwO2g9N2I0NDAx" class="underline">Gropius Bau (Niederkirchnerstraße 7, Kreuzberg, 10963 Berlin)</a></div><div class="w-1/7">21.00</div><div class="w-6/7">Arrival at <a target="_blank" href="https://share.here.com/p/s-Yz1idWlsZGluZztsYXQ9NTIuNTA2MzM7bG9uPTEzLjM1Mzk7bj1LbGluZ2VsaCVDMyVCNmZlcnN0cmElQzMlOUZlKzE0O25sYXQ9NTIuNTA2MTg7bmxvbj0xMy4zNTIyMjtoPTNmMmEwNw" class="underline">Bauhaus Archive (Klingelhöferstraße 14, 10785 Berlin)</a></div>'
      }]
    };
  }

};