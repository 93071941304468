import observeInView from "@/mixins/observeInView.js";
export default {
  mixins: [observeInView],

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      content: "We warmly invite you to join the digital symposium <span class='font-editorial-new-italic'>Re: New European Bauhaus. For A Just Design of Climate Politics </span> which will navigate critical perspectives on the European Green Deal and the New European Bauhaus. The virtual program of talks, discussions and workshops aims to explore institutional, artistic and designerly strategies to utilise the creative fields' response-ability and agency for climate justice in European politics. Additionally, two physical events in Zurich and Berlin will situate the debates locally and offer the chance to connect in person."
    };
  }

};