import observeInView from "@/mixins/observeInView.js";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      text: ['Welcome to the symposium <span class="font-editorial-new-italic">Re: New European Bauhaus. For A Just Design of Climate Politics</span>.', 'Below you find the most important links for participating in the symposium.<br> In the background, you find our collaborative drawing board. Chose a username and feel free to add sketches, comments and questions throughout the event!'],
      links: {
        livestream: 'https://forajustdesignofclimatepolitics.com/#Livestream',
        coc: 'https://forajustdesignofclimatepolitics.com/code-of-conduct',
        bbb: 'https://meetings.udk-berlin.de/b/rob-qnz-o8r-okv'
      }
    };
  }

};