export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      speakers: [{
        name: 'Lisa Hoffmann',
        profession: 'Researcher and Artist',
        description: 'Lisa Hoffmann is an artist, filmmaker, ex-designer, researcher and member of Klasse Klima. <a target="_blank"  href="https://www.lshhhh.net" class="underline">Her work</a> investigates transitional states, daily life fictions & fragmented realities with a focus on ecological anxieties and the decomposition of dominant narratives.'
      }, {
        name: 'Prof. Rolando Vazquez',
        profession: 'Associate Professor of Sociology',
        institution: 'University College Roosevelt of Utrecht University',
        description: 'Rolando Vázquez’ research revolves around postcolonial thinking, visual social experience and the critique of modern time. He is the author of "Vistas of Modernity – decolonial aesthesis and the end of the contemporary" (2020).'
      }, {
        name: 'Nessim Achouche',
        profession: '<a target="_blank"  href="https://www.rosalux.de/en/profile/es_detail/8H5DFHO64X/nessim-achouche?cHash=19d75e27b56cb8e59e4472eb">Project Manager</a>',
        institution: 'Rosa-Luxemburg-Stiftung Brussels',
        description: 'The Rosa-Luxemburg-Stiftung regards climate change as injustice and the central ethical political challenge of our times. Together with partners it works for climate justice and the social-ecological transformation society needs.'
      }, {
        name: 'Dušan Pajović',
        profession: 'Campaign Coordinator',
        institution: '<a target="_blank"  href="https://diem25.org/en/">Democracy in Europe Movement 2025 (DiEM25)</a>',
        description: 'Dušan Pajović is a social psychologist who works as an activist, writer, and campaign coordinator at DiEM25. His interests span post-capitalism, total liberation theory, and freeing humans, animals and the earth of oppression.'
      }, {
        name: 'Hicham Khalidi',
        profession: 'Director',
        institution: '<a target="_blank"  href="https://www.janvaneyck.nl">Jan van Eyck Academie Maastricht</a>',
        description: 'The Jan van Eyck Academie offers residencies to artists, designers, curators, writers and architects from around the world. It has committed itself to exploring the agency and roles of the arts in relation to the climate crisis and its manifold effects.'
      }, {
        name: 'Prof. Claudia Mareis',
        profession: 'Professor of Design and History of Knowledge',
        institution: 'Department of Cultural History and Theory, Humboldt University Berlin',
        description: 'Prof. Claudia Mareis’ research interests include history, theory and methodology of design in the 20th century, knowledge cultures in design, experimental design and media practices, cultural history of creativity, design and material politics.'
      }, {
        name: 'Dr. Christoph Gran',
        profession: 'Economist, Co-Founder ZOE',
        institution: '<a target="_blank"  href="https://zoe-institut.de/en/">ZOE Institute for Future-fit Economies</a>',
        description: 'ZOE is a non-profit and independent think & do tank dedicated to research on a sustainable economy at the interface of politics, science and civil society.'
      }, {
        name: 'Katja Voigt',
        profession: '<a target="_blank"  href="https://www.rosalux.de/profil/es_detail/D2CCO749CP/katja-voigt?cHash=b9bb4f61db83308bb5a06ab3d931f7f5"</a>Senior Advisor, International Climate Politics</a>',
        institution: 'Rosa-Luxemburg-Stiftung',
        description: 'The Rosa-Luxemburg-Stiftung regards climate change as injustice and the central ethical political challenge of our times. Together with partners it works for climate justice and the social-ecological transformation society needs.'
      }, {
        name: 'Marie Rosenkranz',
        profession: 'Doctoral Researcher',
        institution: 'Department of General Sociology, Humboldt University Berlin',
        description: 'Marie Rosenkranz’ research focuses on the changing relationships of art and politics as well as art and society. Art activist practices are her main object of study.'
      }, {
        name: 'Caroline Paulick-Thiel',
        profession: 'Strategic Designer, Director',
        institution: '<a target="_blank"  href="https://www.politicsfortomorrow.eu">Politics for Tomorrow</a>',
        description: 'Politics for Tomorrow is a non-partisan initiative that facilitates participatory processes in the context of societal transformations with political-administrative institutions and civil society.'
      }, {
        name: 'Isabell Schnalle',
        profession: 'Transformation Designer',
        description: 'Isabell Schnalle is a transformation designer, tailor, fashion designer, and member of the initiatives Klasse Klima and Transformation Haus & Feld. Her work operates on the activist and transformative potential of design, work, education and fashion with the focus on eco-feminist narratives and socio-ecological transformation.'
      }, {
        name: 'Tobias Bachmann',
        profession: 'Activist and Political Education Worker',
        institution: '<a target="_blank"  href="https://kipppunkt-kollektiv.de">Kipppunkt Kollektiv</a>',
        description: 'Kipppunkt Kollektiv is a self-organized collective, based in Berlin, that strives for a climate just world with the power of knowledge co-creation. It is strongly committed to cooperation, to tearing down hierarchies and to mutual empowerment.'
      }, {
        name: 'Bobby Jewell',
        profession: 'Steering Group Member and Comms Coordinator',
        institution: '<a target="_blank"  href="https://www.architectscan.org/">Architects Climate Action Network</a>',
        description: 'ACAN is a grass roots organisation addressing the way our built environment is made, operated and renewed in response to the climate emergency. Formed in 2019 the group has spread to a network of thousands with over 10 active territories across the world.'
      }, {
        name: 'Hwei Fan Liang',
        profession: 'Education Team Member, Tutor and Architect',
        institution: '<a target="_blank"  href="https://www.architectscan.org/">Architects Climate Action Network</a>, <a target="_blank"  href="https://www.ravensbourne.ac.uk/people-and-stories/meet-team/hwei-fan-liang">Ravensbourne University London</a>',
        description: 'ACAN is a grass roots organisation addressing the way our built environment is made, operated and renewed in response to the climate emergency. Formed in 2019 the group has spread to a network of thousands with over 10 active territories across the world.'
      }]
    };
  }

};