import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "code-of-conduct": ""
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("embed", {
    src: '/CodeofConduct.pdf',
    class: "w-full h-screen",
    type: "application/pdf"
  }, null, 8, _hoisted_2)]);
}