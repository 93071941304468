import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("img", {
    "snippet-shape-outside": "",
    src: `${$data.publicPath}icons/${$props.src}`,
    style: _normalizeStyle({
      height: $props.height,
      left: $props.isLeft ? '0' : 'auto',
      right: !$props.isLeft ? '0' : 'auto',
      top: $props.top !== undefined ? $props.top : ''
    }),
    class: _normalizeClass(['absolute', 'pointer-events-none'])
  }, null, 12, _hoisted_1), _createElementVNode("div", {
    "snippet-shape-outside": "",
    class: "pointer-events-none",
    style: _normalizeStyle({
      shapeOutside: `url(${$data.publicPath}icons/${$props.src})`,
      float: $props.isLeft ? 'left' : 'right',
      height: $props.height,
      width: $props.width
    })
  }, null, 4)], 64);
}