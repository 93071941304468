export default {
  props: {
    width: String,
    height: String,
    isLeft: Boolean,
    src: String,
    top: String
  },

  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  }

};