import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "page-home": ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SectionRecordings = _resolveComponent("SectionRecordings");

  const _component_SectionAbout = _resolveComponent("SectionAbout");

  const _component_SectionRegistrationClosed = _resolveComponent("SectionRegistrationClosed");

  const _component_SectionProgram = _resolveComponent("SectionProgram");

  const _component_SectionSpeakers = _resolveComponent("SectionSpeakers");

  const _component_SectionBackground = _resolveComponent("SectionBackground");

  const _component_SectionTeam = _resolveComponent("SectionTeam");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SectionRecordings), _createVNode(_component_SectionAbout, {
    class: "p-4 pt-0"
  }), _createVNode(_component_SectionRegistrationClosed, {
    class: "p-4 mb-10"
  }), _createVNode(_component_SectionProgram, {
    class: "p-4 mb-7"
  }), _createVNode(_component_SectionSpeakers, {
    class: "p-4 mb-7"
  }), _createVNode(_component_SectionBackground, {
    class: "p-4 mb-7"
  }), _createVNode(_component_SectionTeam, {
    class: "p-4 pb-5"
  })]);
}