import observeInView from "@/mixins/observeInView.js";
export default {
  mixins: [observeInView],

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      text1: '<a target="_blank" href="https://klasseklima.org" class="underline">Klasse Klima</a> is a dynamic collective of young practicioners from the creative fields and beyond with the aim of establishing the urgency of climate action in art and design education and to create transdisciplinary post-academic learning spaces for the exploration of climate-just creative practice. Klasse Klima started in 2019 as a student-led, ECTS-accredited local seminar series based at the University of the Arts Berlin. Today Klasse Klima increasingly works as a para-institutional organisation towards a structural and broad ecological shift in architecture, art, and design and their institutions. Therefore, the group connects initiatives, students, researchers, practitioners, and intersecting fields that share the aim of creating, designing, and thinking for climate justice. The emerging network and this digital event build on the work of <a target="_blank" href="https://medienhaus.dev/de" class="underline">medienhaus/</a>, a collective of critical-minded and privacy-focused individuals with a common interest in conceptualising digital infrastructure and creating free and open-source software for enabling groups, institutions, and individuals alike to establish and maintain their own self-hosted digital toolkit.',
      text2: 'The Berlin climate education collective Klasse Klima advocates for a just design of the New European Bauhaus and of the European Green Deal. In September 2021, the collective received the €15,000 <a target="_blank" href="https://klasseklima.org/projects/new-european-bauhaus-rising-stars-award" class="underline">New European Bauhaus Rising Stars Award</a> in the category “Interdisciplinary Education Models”. By initiating this symposium, Klasse Klima seeks to take response-ability for the prize through facilitating a public forum for debate.'
    };
  }

};