import { PeerTubePlayer } from '@peertube/embed-api';
export default {
  data() {
    return {
      player: null,
      isPlaying: false
    };
  },

  mounted() {
    this.player = new PeerTubePlayer(this.$refs.iframe);
  },

  methods: {
    play() {
      console.log('try play');
      console.log(this.player);
      this.player.play();
    }

  }
};