import observeInView from "@/mixins/observeInView.js";
export default {
  mixins: [observeInView],

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      text: 'An event initiated by Klasse Klima, in cooperation with Ende der Kunstgeschichte Berlin, kindly supported by the <a target="_blank" href="https://www.rosalux.de/en/">Rosa-Luxemburg-Stiftung</a>, hosted by medienhaus/.'
    };
  }

};