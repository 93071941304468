import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("a", {
    "snippet-welcome-link": "",
    onMouseenter: _cache[0] || (_cache[0] = $event => $data.hover = true),
    onMouseleave: _cache[1] || (_cache[1] = $event => $data.hover = false),
    href: $props.href,
    target: "_blank",
    rel: "noopener",
    class: _normalizeClass(['block', 'pointer-events-auto', 'cursor-pointer'])
  }, [_createElementVNode("img", {
    "snippet-welcome-link": "",
    src: `${$data.publicPath}icons/${$props.src}`,
    style: _normalizeStyle({
      height: $props.height,
      top: $props.top !== undefined ? $props.top : ''
    }),
    class: _normalizeClass(['absolute', 'transform -translate-x-4', 'z-20'])
  }, null, 12, _hoisted_2), _createElementVNode("img", {
    "snippet-welcome-link": "",
    src: `${$data.publicPath}icons/hover/${$props.src}`,
    style: _normalizeStyle({
      height: $props.height,
      top: $props.top !== undefined ? $props.top : ''
    }),
    class: _normalizeClass(['absolute', 'pointer-events-auto', 'cursor-pointer', 'transform -translate-x-4', 'transition-all duration-200', 'z-20', $data.hover ? 'opacity-100' : 'opacity-0'])
  }, null, 14, _hoisted_3), _createElementVNode("div", {
    "snippet-shape-outside": "",
    class: "pointer-events-none",
    style: _normalizeStyle({
      shapeOutside: `url(${$data.publicPath}icons/${$props.src})`,
      float: 'left',
      height: $props.height,
      width: $props.width
    })
  }, null, 4)], 40, _hoisted_1);
}