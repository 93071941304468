import slugify from "@/mixins/slugify.js";
export default {
  props: {
    section: Object
  },
  mixins: [slugify],

  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  }

};