import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "page-imprint": "",
  class: "p-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasicText = _resolveComponent("BasicText");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BasicText, {
    size: 'sm',
    text: $data.text
  }, null, 8, ["text"])]);
}