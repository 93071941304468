export default {
  name: "PageHome",
  computed: {},

  data() {
    return {
      mounted: false
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.mounted = true;
    });
  }

};