import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_LayoutNav = _resolveComponent("LayoutNav");

  return _openBlock(), _createElementBlock("main", _hoisted_1, [_createVNode(_component_router_view, {
    class: "z-50"
  }, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: $setup.route.path
    }))]),
    _: 1
  }), _ctx.$route.name === 'Home' ? (_openBlock(), _createBlock(_component_LayoutNav, {
    key: 0
  })) : _createCommentVNode("", true)]);
}