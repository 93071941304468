 export default {
     data() {
       return {
         inView: false,
         observer: null,
         prevRatio: 0,
         observerOptions: {
           root: null,
           rootMargin: "0px",
           threshold: 0.1
         }
       }
     },
    methods: {
      observeInView(el) {
        this.observer = new IntersectionObserver(this.handleIntersect, this.observerOptions);
        this.observer.observe(el);
      },
      handleIntersect(entries, observer) {
          entries.forEach((entry) => {
            console.log(entry.intersectionRatio)

          if (entry.intersectionRatio > this.prevRatio) {
            this.inView = true
          } else {
            this.inView = false
          }

          this.prevRatio = entry.intersectionRatio;
        });
      }
   }
}
