import observeInView from "@/mixins/observeInView.js";
import { reactive, ref } from 'vue';
export default {
  mixins: [observeInView],

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      content: "The registration is now closed. If you have already registered, you have received further information via e-mail. The registration enables you to interact with the panels via BigBlueButton and to participate in the workshops. All talks will also be livestreamed to this website and can be accessed without registration."
    };
  }

};