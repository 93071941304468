import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Welcome from '../views/Welcome.vue'
import Entangle from '../views/Entangle.vue'
import CodeofConduct from '../views/CodeofConduct.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/entangle',
    name: 'Entangle',
    component: Entangle
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
    {
    path: '/code-of-conduct',
    name: 'CodeofConduct',
    component: CodeofConduct
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
