import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1fcb9374"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "basic-label": "",
    lang: "en",
    class: _normalizeClass(['inline-block', 'pb-0.5 pt-1.5 px-2', 'text-xs font-editorial-new', 'text-gray-800', 'rounded-full', 'bg-secondary-color']),
    innerHTML: $props.text
  }, null, 10, _hoisted_1);
}