export default {
  props: {
    src: String,
    target: String
  },

  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  }

};