import { useRoute } from 'vue-router';
export default {
  data() {
    return {
      queue: null
    };
  },

  setup() {
    return {
      route: useRoute()
    };
  }

};