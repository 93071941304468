import observeInView from "@/mixins/observeInView.js";
export default {
  mixins: [observeInView],

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      text1: 'In December 2019, the European Commission launched the <a target="_blank" href="https://ec.europa.eu/info/strategy/priorities-2019-2024/european-green-deal_en" class="underline">European Green Deal (EGD)</a>, a set of policy initiatives to make the European Union climate neutral by 2050. Quickly, the Commission identified architecture and urban planning as crucial sectors for restructuring: construction accounts for approx. 40% of EU emissions. Thus, in October 2020, the Commission introduced the <a target="_blank" href="https://europa.eu/new-european-bauhaus/index_en" class="underline">New European Bauhaus (NEB)</a> initiative, a transdisciplinary project to engage architects, designers and other creative practitioners, together with scholars, scientists, students and the larger civil society, in the green transition of the EU.'
    };
  }

};