import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ebf1a660"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['font-editorial-new', $props.uppercase ? 'uppercase' : ''])
  }, [$props.size === 'xl' ? (_openBlock(), _createElementBlock("h2", {
    key: 0,
    "basic-headline": "",
    class: "text-xl",
    innerHTML: $props.text
  }, null, 8, _hoisted_1)) : _createCommentVNode("", true), $props.size === 'lg' ? (_openBlock(), _createElementBlock("h3", {
    key: 1,
    "basic-headline": "",
    class: "text-lg",
    innerHTML: $props.text
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true), $props.size === 'md' ? (_openBlock(), _createElementBlock("h4", {
    key: 2,
    "basic-headline": "",
    class: "text-md leading-none",
    innerHTML: $props.text
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true), $props.size === 'sm' ? (_openBlock(), _createElementBlock("h5", {
    key: 3,
    "basic-headline": "",
    class: "text-sm leading-none",
    innerHTML: $props.text
  }, null, 8, _hoisted_4)) : _createCommentVNode("", true), $props.size === 'xs' ? (_openBlock(), _createElementBlock("h6", {
    key: 4,
    "basic-headline": "",
    class: "text-xs",
    innerHTML: $props.text
  }, null, 8, _hoisted_5)) : _createCommentVNode("", true)], 2);
}