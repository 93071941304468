import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-47cef48a"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "basic-text": "",
    lang: "en",
    class: _normalizeClass(['text', 'text-primary-color ', {
      'text-md': $props.size === undefined || 'md',
      'text-sm': $props.size === 'sm',
      'text-xs': $props.size === 'xs',
      'font-editorial-new': $props.style === undefined,
      'font-editorial-new-italic': $props.style === 'italic',
      'font-diatype': $props.style === 'sans'
    }]),
    innerHTML: $props.text
  }, null, 10, _hoisted_1);
}