import slugify from "@/mixins/slugify.js";
import observeInView from "@/mixins/observeInView.js";
export default {
  props: {
    speaker: Object,
    index: Number
  },
  mixins: [slugify, observeInView],

  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.observeInView(this.$el);
    });
  }

};