import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "snippet-section": ""
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasicText = _resolveComponent("BasicText");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BasicText, {
    size: 'md',
    text: $props.section.title,
    class: _normalizeClass(['py-2'])
  }, null, 8, ["text"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.section.speakers, (speaker, index) => {
    return _openBlock(), _createElementBlock("a", {
      href: `#${_ctx.slugify(speaker)}`,
      class: _normalizeClass(['inline-block mr-2', 'bg-primary-color bg-opacity-10', 'pt-1.5 pb-1 px-2'])
    }, [_createVNode(_component_BasicText, {
      size: 'sm',
      text: speaker
    }, null, 8, ["text"])], 10, _hoisted_2);
  }), 256))]);
}