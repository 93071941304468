import _throttle from 'lodash.throttle';
export default {
  data() {
    return {
      isVisible: false,
      mounted: false,
      currentScrollPosition: 0,
      lastScrollPosition: 0
    };
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  mounted() {
    this.mounted = true;
  },

  methods: {
    handleScroll: _throttle(function () {
      this.currentScrollPosition = window.scrollY;

      if (window.scrollY < window.innerHeight / 2) {
        this.isVisible = false;
      } else if (window.scrollY > window.innerHeight / 2 && window.scrollY < window.innerHeight * 1.75) {
        this.isVisible = true;
      } else if (this.currentScrollPosition > this.lastScrollPosition) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }

      this.lastScrollPosition = this.currentScrollPosition;
    }, 250)
  }
};