import slugify from "@/mixins/slugify.js";
export default {
  props: {
    day: Object,
    title: String
  },

  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  }

};